import React from "react"
import { Link } from "gatsby";
import styled from "styled-components";


const Wrapper = styled.div`
	display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  a {
    text-decoration: none !important;
  }
`

const Content = styled.div`
  border-radius: 5px;
  ${({ theme }) => theme.dp.two}
  :hover {
    ${({ theme }) => theme.dp.eight}
		transition: .5s;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  img {
    margin: 0 !important;
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
  }
`

const Title = styled.p`
  margin: 0 !important;
  padding: 10px;
`

const RecommendArticles = ({ posts }) => {
	if (posts === null) {
		return (
			<p>関連記事なし</p>
		)
  }
	return (
		<Wrapper>
			{posts.map(post => (
        <Content
          key={post.fields.slug}
        >
					<Link
						to={post.fields.slug}
					>
            <ImageWrapper>
              <img
                width="540"
                height="260"
                src={post.frontmatter.hero.publicURL}
                alt={post.frontmatter.title}
                loading="lazy"
              />
						</ImageWrapper>
						<Title>
							{post.frontmatter.title}
						</Title>
					</Link>
				</Content>
			))}
		</Wrapper>
	)
}

export default RecommendArticles