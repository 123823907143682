import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby"
import AppContext from "@contexts/AppContext"
import styled from "styled-components";


const Wrapper = styled.div`
  margin: 30px 0;
  .share-text {
    margin: 25px 0 20px;
    color: ${({ theme }) => theme.text.post} !important;
    font-size: 1.5em;
    text-align: center;
    user-select: none;
  }
`

const Items = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, minmax(40px, 1fr));
  grid-gap: 20px;
`

const Item = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 !important;
  color: ${({ theme }) => theme.text.post} !important;
  text-align: center;
  font-size: 25px;
  white-space: nowrap;
  :hover {
    text-decoration: none !important;
    transform: translateY(-2.5px);
  }
`

const SharePost = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          social {
            twitter
          }
        }
      }
      twitter: file(relativePath: {regex: "/socials/twitter/"}) {
        publicURL
			}
			facebook: file(relativePath: {regex: "/socials/facebook/"}) {
        publicURL
			}
			pocket: file(relativePath: {regex: "/socials/pocket/"}) {
        publicURL
			}
			line: file(relativePath: {regex: "/socials/line/"}) {
        publicURL
			}
			hatena: file(relativePath: {regex: "/socials/hatena/"}) {
    		publicURL
  		}
    }
	`)
  const twitter = data.twitter.publicURL
  const facebook = data.facebook.publicURL
  const pocket = data.pocket.publicURL
  const line = data.line.publicURL
  const hatena = data.hatena.publicURL
  
  const value = useContext(AppContext)
  const pageText = `${value.title}`
  const pageUrl = `${data.site.siteMetadata.siteUrl}${value.slug}`
  const twitterId = data.site.siteMetadata.social.twitter

  return (
    <Wrapper>
      <div className="share-text">SHARE</div>
      <Items>
        <Item
          href={`https://b.hatena.ne.jp/add?&url=${pageUrl}`}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <img src={hatena} width="30" height="30" alt="hatena" style={{ margin: "0" }} />
        </Item>
        <Item
          href={`https://getpocket.com/edit?url=${pageUrl}`}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <img src={pocket} width="30" height="30" alt="pocket" style={{ margin: "0" }} />
        </Item>
        <Item
          href={`https://twitter.com/share?text=${pageText}&url=${pageUrl}&via=${twitterId}`}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <img src={twitter} width="30" height="30" alt="twitter" style={{ margin: "0" }} />
        </Item>
        <Item
          href={`https://social-plugins.line.me/lineit/share?url=${pageUrl}`}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <img src={line} width="30" height="30" alt="line" style={{ margin: "0" }} />
        </Item>
        <Item
          href={`https://www.facebook.com/share.php?u=${pageUrl}`}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <img src={facebook} width="30" height="30" alt="facebook" style={{ margin: "0" }} />
        </Item>
      </Items>
    </Wrapper>
  )
}

export default SharePost